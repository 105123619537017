import React, { useContext }  from "react"
import { Link } from "gatsby"

import AutoCheckButton from "../autocheckButton/AutoCheckButton"
import { FinanceCalculatorContext } from "../modal/financing/storage/CalculatorContext"
import FinanceCalculator from "../../Modules/financeCalculator"
import useIncentives from "./hooks/VDPIncentives"
import useOptionalIncentives from "./hooks/VDPOptionalIncentives"
import { captureEvent } from 'event-service/modules/capture'

import { configurableString, configurableLink } from '../../Modules/configurableVehicleInfo'
import InnerHTML from 'dangerously-set-html-content'

import style from "./vdp.module.scss"
export default function VDPFinancing(props) {
  const { Pricing, ListOfAppliedOffers, ListOfAvailableOffers } = props.vehicle
  const {IsNew} = props.vehicle.VehicleInfo
  const { netPriceCustomText } = props
  const { months, downpayment, rates } = props.financing
  const { buttons, fallbackText } = props
  const { PosDifText, NegDifText } = props
  const { retailPriceCustomText,retailPriceDiscountCustomText,dealerPriceCustomText } = props
  const { asLowAs, unavailableImg } = props
  const { strikeMSRP, AutoCheckEnabled } = props
  const topRate = rates[0]
  const price = Number(Pricing.ExtraPrice1 || Pricing.List)
  const [renderIncentives, discountSum] = useIncentives(ListOfAppliedOffers,Pricing)
  const [renderOptionalIncentives, optionalDiscountSum] = useOptionalIncentives(ListOfAvailableOffers,Pricing)
  const [,incentivesSum]  = useIncentives(ListOfAppliedOffers,Pricing)
  const calc = new FinanceCalculator(price)
  const monthly = calc.calculateMonthlyPayment(
    price, discountSum, calc.rebate, months, topRate, downpayment
  )

  const { replacePrice, newPriceText } = props

  const renderMSRPRetailLabel = (isNew,Pricing) => {
    return isNew ? "MSRP" : Pricing.List > Pricing.ExtraPrice1 ? retailPriceDiscountCustomText: retailPriceCustomText
  }


  const [, setFinanceModalContext] = useContext(FinanceCalculatorContext)
  const contextualizeItem = () => {
    setFinanceModalContext({ finalPrice: price - discountSum, vehicleInfo: props.vehicle.VehicleInfo })
  }

  const configurablePriceInfo = price - incentivesSum;
  
  const dataLayer = (...args) => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(...args);
    }
  };
 
  const setStyle = (button) => {
    if (button.hasOwnProperty("overrideTheme") && button.overrideTheme == true) {
      const styles = [
        button.hasOwnProperty("btncolor") && ["backgroundColor", button.btncolor],
        button.hasOwnProperty("btntxtcolor") && ["color", button.btntxtcolor]
      ].filter(el => el != false)
      return Object.fromEntries(styles)
    }
  }
  const showPhoto = () => {
    let mainPhoto = {}
    if (props.vehicle.MainPhotoUrl === "") {
      mainPhoto = unavailableImg
    } else {
      mainPhoto =  props.vehicle.MainPhotoUrl;
    }
    return mainPhoto
  }
  const renderHTMLBtn = (html) => (
    <div className="vdp-html-btns" data-vehicle-info={JSON.stringify({ ...props.vehicle.VehicleInfo, price: props.vehicle.Pricing.ExtraPrice1})} onClick={() => {
        dataLayer({
              'event': 'CTA_event',
              'VIN': props.vehicle.VehicleInfo.VIN,
              'MSRP': props.vehicle.Pricing.List,
              'reduced_price': props.vehicle.Pricing.ExtraPrice1
            }
        )}}>
      <InnerHTML html={configurableString(html, props.vehicle.VehicleInfo, showPhoto(), configurablePriceInfo)} />
    </div>
  )
   


  return (
      <>
        <div id="details_plus_wrapper" className={style["normal-vdpf"]}>
          <section id="details_plus" className={style["details"]}>
            <div id="details_plus_inner" className={style["details__inner"]}>
              <ul className={style["offers"]}>
                { replacePrice ? 
                    <li className={style["offer"]}>
                      <p className={style["offer-name"]}>
                        { newPriceText !== undefined ? newPriceText : '' }
                      </p>
                    </li>
                  :
                  (Pricing.ExtraPrice1 > Pricing.List && Pricing.ExtraPrice1 > 0  && Pricing.List > 0 && !IsNew)
                      ?
                      <span></span>
                      :
                  (Pricing.ExtraPrice1 < Pricing.List && Pricing.List > 0 && Pricing.ExtraPrice1 > 0 && (Pricing.Special == 0 ||Pricing.Special == Pricing.List || Pricing.Special == Pricing.ExtraPrice1)) ? <li className={style["offer"]}>
                        <div className={style["offer-cont"]} style={{marginBottom: "7px", opacity: "0.5"}}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>DETAILED PRICING</button></p>
                          <p className={style["offer-value"] + " " + ((IsNew && strikeMSRP) ? style["strike-through"]: "")}>
                            <span className={style["offer-price-name"]}>{renderMSRPRetailLabel(IsNew,Pricing)}</span>
                            {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                          </p>
                        </div>
                        {Pricing.ExtraPrice1 > 0 &&
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>{ NegDifText}</button></p>
                          <p className={style["offer-value"]}>
                            {Pricing.ExtraPrice1 > 0 ? `-$${FinanceCalculator.formatLocale(Pricing.List - Pricing.ExtraPrice1)}` : fallbackText}
                          </p>
                        </div>
                        }
                      </li> :
                      ((Pricing.ExtraPrice1 > Pricing.List && Pricing.List > 0) || (Pricing.Special < Pricing.List && Pricing.List != Pricing.Special && Pricing.Special > 0)) ? <li className={style["offer"]}>
                            <div className={style["offer-cont"]} style={{marginBottom: "7px", opacity: "0.5"}}>
                              <p className={style["offer-name"]}><button className={style["offer-btn"]}>DETAILED PRICING</button></p>
                              <p className={style["offer-value"] + " " + ((IsNew && strikeMSRP) ? style["strike-through"]: "")}>
                                <span className={style["offer-price-name"]}>{renderMSRPRetailLabel(IsNew,Pricing)}</span>
                                {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                              </p>
                            </div>

                            {(Pricing.Special > 0 && Pricing.Special < Pricing.ExtraPrice1 && Pricing.Special != Pricing.List) ?
                              <>
                                <div className={style["offer-cont"]}>
                                  <p className={style["offer-name"]}><button className={style["offer-btn"]}>{ NegDifText }</button></p>
                                  <p className={style["offer-value"]}>
                                    -${FinanceCalculator.formatLocale(Pricing.List - Pricing.Special)}
                                  </p>
                                </div>
                                  <div className={style["offer-cont"]}>
                                    <p className={style["offer-name"]}><button className={style["offer-btn"]}>{ PosDifText }</button></p>
                                    <p className={style["offer-value"]}>
                                      {Pricing.ExtraPrice1 > 0 ? `+$${FinanceCalculator.formatLocale(Pricing.ExtraPrice1 - Pricing.Special)}` : fallbackText}
                                    </p>
                                  </div>
                                </>
                                :

                              <div className={style["offer-cont"]}>
                              <p className={style["offer-name"]}><button className={style["offer-btn"]}>{ PosDifText }</button></p>
                              <p className={style["offer-value"]}>
                            {Pricing.ExtraPrice1 > 0 ? `+$${FinanceCalculator.formatLocale(Pricing.ExtraPrice1 - Pricing.List)}` : fallbackText}
                              </p>
                              </div>
                            }
                          </li> :
                      (Pricing.ExtraPrice1 == Pricing.List && price != 0  && discountSum > 0)
                          ?
                          <li className={style["offer"]}>
                            <div className={style["offer-cont"]} style={{marginBottom: "7px", opacity: "0.5"}}>
                              <p className={style["offer-name"]}><button className={style["offer-btn"]}>DETAILED PRICING</button></p>
                              <p className={style["offer-value"]}>
                                <span className={style["offer-price-name"]}>MSRP</span>
                                {Pricing.List > 0 ? `$${FinanceCalculator.formatLocale(Pricing.List)}` : fallbackText}
                              </p>
                            </div>
                          </li>:
                      <li className={style["offer"]}>
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}><button className={style["offer-btn"]}>{(Pricing.ExtraPrice1 == Pricing.List && price != 0) && IsNew ? "MSRP" : "Dealer Price"}</button></p>
                          <p className={style["offer-value"]}>
                            {price > 0 ? `$${FinanceCalculator.formatLocale(price)}` : fallbackText}
                          </p>
                        </div>
                      </li>
                }
                { ListOfAppliedOffers.length > 0 && renderIncentives() }
                {((Pricing.ExtraPrice1 != Pricing.List && Pricing.List > 0) && (discountSum == 0) && !((Pricing.ExtraPrice1 > Pricing.List) && !IsNew)) &&
                   <hr style={{ width: "100%" }} />
                }
           
                  { (((Pricing.ExtraPrice1 != Pricing.List && Pricing.List > 0 && Pricing.ExtraPrice1 > 0) || (discountSum > 0)) ) &&
                    <div className={style["offer-cont"]}>
                      {netPriceCustomText? <b style={{ fontSize: "1.25em" }}>{ netPriceCustomText }</b> : <b style={{ fontSize: "1.25em" }}>Net price</b>}
                      <p style={{ fontSize: "1.25em" }}>
                        {`$${FinanceCalculator.formatLocale(price - discountSum)}`}
                      </p>
                    </div>
                  }


                {
                  (((Pricing.ExtraPrice1 - Pricing.List) < 0) && ((discountSum > 0 || (Pricing.List - Pricing.ExtraPrice1) > 0 ) && Pricing.ExtraPrice1 != 0)) && (
                      <>
                        <div className={style["offer-cont-savings"]}>
                          <b>Total savings</b>
                          <p>{`$${FinanceCalculator.formatLocale(discountSum + (Pricing.List - Pricing.ExtraPrice1))}`}</p>
                        </div>
                        <hr style={{ width: "100%" }} />
                      </>
                  )
                }
                {
                  (price > 0 && asLowAs) && (
                      <li className={style["offer"]}>
                        <div className={style["offer-cont"]}>
                          <p className={style["offer-name"]}>
                            <button className={style["offer-btn"]}></button>
                          </p>
                            <Link className={style["offer-value"]} to={"#financecal"}>
                            <button className={style["offer-btn offer-btn_underline"] + " " + style["offer-finance"]} onClick={contextualizeItem}>
                              {`As Low As $${FinanceCalculator.formatLocale(monthly)}/Mo`}
                            </button>
                            </Link>
                        </div>
                      </li>
                  )
                }
              </ul>
              { ListOfAvailableOffers.length > 0 &&  renderOptionalIncentives()}
            <div className={`${style.controls} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-controls' : 'used-vdp-controls'}`}>
              {buttons.map(button => (
                  (button.html != undefined && button.html.split(' ').join('') === "{{carfax}}")?
                  (props.vehicle.VehicleInfo.IsNew === false)?       
                      (props.vehicle.VehicleInfo.VehicleHistoryReportLink) ?
                  
                        <a style={{textAlign: "center", marginLeft:'4px', color: '#ffffff', alignItems: 'center', display: 'flex', cursor: 'pointer', justifyContent: 'center', marginBottom: '24px', marginTop: '20px', width: "100%", minHeight: '48px'}} href={props.vehicle.VehicleInfo.VehicleHistoryReportLink} target="_blank">
                        <img style={{ maxHeight: '150px', maxWidth: '225px'}} src={props.vehicle.VehicleInfo.Category5} />
                      </a>
          
                      :
                 
                      <a style={{textAlign: "center", marginLeft:'4px', color: '#ffffff', alignItems: 'center', display: 'flex', cursor: 'pointer', justifyContent: 'center', marginBottom: '24px', marginTop: '20px', width: "100%", minHeight: '48px'}} href={`http://www.carfax.com/cfm/ccc_DisplayHistoryRpt.cfm?vin=${props.vehicle.VehicleInfo.VIN}`}  target="_blank">
                        <img style={{ maxHeight: '150px', maxWidth: '225px'}}  src="https://media.dealermasters.com/Carfax/carfax-general.webp" />
                      </a> 
                    :
                    <></>
                
              
                :  
                (button.html != undefined && button.html != '' && button.html != '<p></p>')
                  ? (button.html === "{{autocheck}}") ? 
                  <AutoCheckButton 
                    vin={props.vehicle.VehicleInfo.VIN} 
                    IsNew={props.vehicle.VehicleInfo.IsNew} 
                    AutoCheckEnabled={AutoCheckEnabled} 
                  /> : renderHTMLBtn(button.html)
                  :
                             
                  (button.buttonLink.includes('tel:')
                    ?
                    <a className={`${style["control-a-wrap"]} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase() : 'used-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase()}`} href={button.buttonLink}>
                      <button className={style["control"]} style={setStyle(button)}
                        onClick={event => {
                          captureEvent({
                            event: 'asc_cta_interaction',
                            element_text: `${event.currentTarget.innerText}`,
                            element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                            element_type: 'button',
                            event_action: 'click',
                            event_action_result: 'open form',
                            link_url: `${button.buttonLink}`,
                            item_id: props.vehicle.VehicleInfo.VIN,
                            item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                            item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                            item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                            item_year: `${props.vehicle.VehicleInfo.Year}`,
                            item_make: `${props.vehicle.VehicleInfo.Make}`,
                            item_model: `${props.vehicle.VehicleInfo.Model}`,
                            item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                            item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                            item_type: '',
                            item_category: '',
                            item_fuel_type: '',
                            item_inventory_date: ''
                          }, {
                            handlers: ['ASC']
                          }).then(res => console.log(res)).catch(err => console.log(err))
                          dataLayer({
                            'event': 'CTA_event',
                            'VIN': props.vehicle.VehicleInfo.VIN,
                            'MSRP': Pricing.List,
                            'reduced_price': Pricing.ExtraPrice1
                          });
                          contextualizeItem();
                        }}>
                        {
                          (button.buttonImage && button.buttonImage.length > 0) ? (
                            <img src={button.buttonImage} style={{ height: "100%" }} />
                          ) : button.buttonText
                        }
                      </button>
                    </a>
                    :
                    <Link className={`${style["control-a-wrap"]} ${props.vehicle.VehicleInfo.IsNew ? 'new-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase() : 'used-vdp-btn-'+button.buttonText.split(" ").join('-').toLowerCase()}`} to={configurableString(button.buttonLink, props.vehicle.VehicleInfo)}>
                      <button className={style["control"]} style={setStyle(button)}
                        onClick={event => {
                          captureEvent({
                            event: 'asc_cta_interaction',
                            element_text: `${event.currentTarget.innerText}`,
                            element_color: `${window.getComputedStyle(event.currentTarget).color}`,
                            element_type: 'button',
                            event_action: 'click',
                            event_action_result: 'open form',
                            link_url: `${button.buttonLink}`,
                            item_id: props.vehicle.VehicleInfo.VIN,
                            item_number: `${props.vehicle.VehicleInfo.Year} ${props.vehicle.VehicleInfo.Make} ${props.vehicle.VehicleInfo.Model} ${props.vehicle.VehicleInfo.Trim}`,
                            item_price: `${props.vehicle.Pricing.ExtraPrice1}`,
                            item_condition: `${props.vehicle.VehicleInfo.IsNew ? 'New' : 'Used'}`,
                            item_year: `${props.vehicle.VehicleInfo.Year}`,
                            item_make: `${props.vehicle.VehicleInfo.Make}`,
                            item_model: `${props.vehicle.VehicleInfo.Model}`,
                            item_variant: `${props.vehicle.VehicleInfo.Trim}`,
                            item_color: `${props.vehicle.VehicleInfo.ExteriorColor}`,
                            item_type: '',
                            item_category: '',
                            item_fuel_type: '',
                            item_inventory_date: ''
                          }, {
                            handlers: ['ASC']
                          }).then(res => console.log(res)).catch(err => console.log(err))
                          dataLayer({
                            'event': 'CTA_event',
                            'VIN': props.vehicle.VehicleInfo.VIN,
                            'MSRP': Pricing.List,
                            'reduced_price': Pricing.ExtraPrice1
                          });
                          contextualizeItem();
                        }}>
                        {
                          (button.buttonImage && button.buttonImage.length > 0) ? (
                            <img src={button.buttonImage} style={{ height: "100%" }} />
                          ) : configurableString(button.buttonText, props.vehicle.VehicleInfo)
                        }
                      </button>
                    </Link>                       
                  )
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

VDPFinancing.defaultProps = {
  financing: {
    months: 0, downpayment: 0
  }
}
